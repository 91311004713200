$(document).ready(function () {
    // User Dropdown
    var $userDropdown = $('#userDropdown');
    var $userDropdownTrigger = $('#userDropdownTrigger');
    new Dropdown({
        el: $userDropdown,
        elTrigger: $userDropdownTrigger,
    });

    // Account Dropdown
    var $accountDropdown = $('#accountDropdown');
    var $accountDropdownTrigger = $('#accountDropdownTrigger');
    new Dropdown({
        el: $accountDropdown,
        elTrigger: $accountDropdownTrigger,
    });

    // Event Dropdown
    var $eventDropdown = $('#eventDropdown');
    var $eventDropdownTrigger = $('#eventDropdownTrigger');
    new Dropdown({
        el: $eventDropdown,
        elTrigger: $eventDropdownTrigger,
    });
});
