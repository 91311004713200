import './Dropdown.css'

var Dropdown = function(options) {
    // Regions
    this.el = options.el;
    this.elTrigger = options.elTrigger;
    this.id = options.el.id;

    // States & variables
    this.shown = false;

    // Proxy functions
    var toggle = this.toggle.bind(this);
    var bindDocumentClick = this.bindDocumentClick.bind(this);
    var hide = this.hide.bind(this);

    // Button event listeners
    this.elTrigger.on('click', toggle);

    // Close this dropdown when clicking outside the element or button
    bindDocumentClick();
};

Dropdown.prototype.toggle = function(e) {
    e.preventDefault();
    this.el.toggleClass('hidden');
    this.elTrigger.toggleClass('dropdownIsOpen');
    this.shown = !this.shown;
};

Dropdown.prototype.bindDocumentClick = function() {
    var self = this;
    $(document).on('click', function(e) {
        if(self.shown) {
            var clickedSelf = $(e.target).is(self.el);
            var clickedChildren = $(self.el).has(e.target).length > 0;
            var clickedButton = $(e.target).is(self.elTrigger);
            var clickedButtonChildren = $(self.elTrigger).has(e.target).length > 0;

            if(!clickedSelf && !clickedChildren && !clickedButton && !clickedButtonChildren) {
                self.hide();
            }
        }
    })
};

Dropdown.prototype.hide = function() {
    this.el.addClass('hidden');
    this.elTrigger.removeClass('dropdownIsOpen');
    this.shown = false;
};

window.Dropdown = Dropdown;
